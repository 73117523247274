
export default defineNuxtRouteMiddleware((to) => {
  const { $localePath } = useNuxtApp();
  const router = useRouter();
  const $auth = useAuth();

  if (!$auth.loggedIn) return;

  const routeTenantSlug = to.params.tenantSlug;
  if (routeTenantSlug && $auth.user?.workspace.slug && routeTenantSlug !== $auth.user?.workspace.slug) return;

  const redirectRoute = router.resolve(to.query.redirectTo as string
    || window.localStorage?.getItem('redirectTo')
    || $localePath({ name: 't-tenantSlug-m', params: { tenantSlug: $auth.user?.workspace.slug } }));

  // Prevent redirect to another tenant
  if (redirectRoute?.params?.tenantSlug !== routeTenantSlug) {
    window.localStorage?.removeItem('redirectTo');
    return;
  }

  // eslint-disable-next-line consistent-return
  return navigateTo(redirectRoute);
});
